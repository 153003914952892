export const SansFont = {
    smufl: false,
    name: 'SANS',
    spacing: 50,
    Description: 'Built-in sans-serif font',
    bold: true,
    italic: true,
    monospaced: false,
    serifs: false,
    glyphs: {
        '0': {
            x_min: 85,
            x_max: 1041,
            y_min: -25,
            y_max: 1472,
            ha: 1497,
            leftSideBearing: 85,
            advanceWidth: 1139,
        },
        '1': {
            x_min: 223,
            x_max: 763,
            y_min: 0,
            y_max: 1472,
            ha: 1472,
            leftSideBearing: 223,
            advanceWidth: 1139,
        },
        '2': {
            x_min: 61.840001123045234,
            x_max: 1031,
            y_min: 0,
            y_max: 1472,
            ha: 1472,
            leftSideBearing: 61,
            advanceWidth: 1139,
        },
        '3': {
            x_min: 86,
            x_max: 1046,
            y_min: -26,
            y_max: 1472,
            ha: 1498,
            leftSideBearing: 86,
            advanceWidth: 1139,
        },
        '4': {
            x_min: 26,
            x_max: 1040,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 26,
            advanceWidth: 1139,
        },
        '5': {
            x_min: 85,
            x_max: 1057,
            y_min: -25,
            y_max: 1446,
            ha: 1471,
            leftSideBearing: 85,
            advanceWidth: 1139,
        },
        '6': {
            x_min: 77,
            x_max: 1045,
            y_min: -25,
            y_max: 1472,
            ha: 1497,
            leftSideBearing: 77,
            advanceWidth: 1139,
        },
        '7': {
            x_min: 97,
            x_max: 1046,
            y_min: 0,
            y_max: 1447,
            ha: 1447,
            leftSideBearing: 97,
            advanceWidth: 1139,
        },
        '8': {
            x_min: 83,
            x_max: 1049,
            y_min: -25,
            y_max: 1472,
            ha: 1497,
            leftSideBearing: 83,
            advanceWidth: 1139,
        },
        '9': {
            x_min: 85,
            x_max: 1049,
            y_min: -25,
            y_max: 1472,
            ha: 1497,
            leftSideBearing: 85,
            advanceWidth: 1139,
        },
        ' ': {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
            ha: 0,
            leftSideBearing: 0,
            advanceWidth: 569,
        },
        '!': {
            x_min: 176,
            x_max: 399,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 176,
            advanceWidth: 569,
        },
        '"': {
            x_min: 94,
            x_max: 631,
            y_min: 947,
            y_max: 1466,
            ha: 519,
            leftSideBearing: 94,
            advanceWidth: 727,
        },
        '#': {
            x_min: 21,
            x_max: 1113,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 21,
            advanceWidth: 1139,
        },
        $: {
            x_min: 73,
            x_max: 1043,
            y_min: -211,
            y_max: 1601,
            ha: 1812,
            leftSideBearing: 73,
            advanceWidth: 1139,
        },
        '%': {
            x_min: 119,
            x_max: 1695,
            y_min: -54,
            y_max: 1491,
            ha: 1545,
            leftSideBearing: 119,
            advanceWidth: 1821,
        },
        '&': {
            x_min: 88,
            x_max: 1319,
            y_min: -34,
            y_max: 1491,
            ha: 1525,
            leftSideBearing: 88,
            advanceWidth: 1366,
        },
        "'": {
            x_min: 90,
            x_max: 295,
            y_min: 947,
            y_max: 1466,
            ha: 519,
            leftSideBearing: 90,
            advanceWidth: 391,
        },
        '(': {
            x_min: 124,
            x_max: 608,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 124,
            advanceWidth: 682,
        },
        ')': {
            x_min: 124,
            x_max: 608,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 124,
            advanceWidth: 682,
        },
        '*': {
            x_min: 64,
            x_max: 725,
            y_min: 867,
            y_max: 1491,
            ha: 624,
            leftSideBearing: 64,
            advanceWidth: 797,
        },
        '+': {
            x_min: 114,
            x_max: 1082,
            y_min: 237,
            y_max: 1206,
            ha: 969,
            leftSideBearing: 114,
            advanceWidth: 1196,
        },
        ',': {
            x_min: 170,
            x_max: 387,
            y_min: -290,
            y_max: 205,
            ha: 495,
            leftSideBearing: 170,
            advanceWidth: 569,
        },
        '-': {
            x_min: 65,
            x_max: 618,
            y_min: 440,
            y_max: 621,
            ha: 181,
            leftSideBearing: 65,
            advanceWidth: 682,
        },
        '.': {
            x_min: 186,
            x_max: 391,
            y_min: 0,
            y_max: 205,
            ha: 205,
            leftSideBearing: 186,
            advanceWidth: 569,
        },
        '/': {
            x_min: 0,
            x_max: 569,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 0,
            advanceWidth: 569,
        },
        ':': {
            x_min: 185,
            x_max: 390,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 185,
            advanceWidth: 569,
        },
        ';': {
            x_min: 170,
            x_max: 387,
            y_min: -290,
            y_max: 1062,
            ha: 1352,
            leftSideBearing: 170,
            advanceWidth: 569,
        },
        '<': {
            x_min: 112,
            x_max: 1083,
            y_min: 226,
            y_max: 1219,
            ha: 993,
            leftSideBearing: 112,
            advanceWidth: 1196,
        },
        '=': {
            x_min: 114,
            x_max: 1082,
            y_min: 417,
            y_max: 1030,
            ha: 613,
            leftSideBearing: 114,
            advanceWidth: 1196,
        },
        '>': {
            x_min: 112,
            x_max: 1083,
            y_min: 226,
            y_max: 1219,
            ha: 993,
            leftSideBearing: 112,
            advanceWidth: 1196,
        },
        '?': {
            x_min: 90,
            x_max: 1036,
            y_min: 0,
            y_max: 1491,
            ha: 1491,
            leftSideBearing: 90,
            advanceWidth: 1139,
        },
        '@': {
            x_min: 111,
            x_max: 2005,
            y_min: -431,
            y_max: 1493,
            ha: 1924,
            leftSideBearing: 111,
            advanceWidth: 2079,
        },
        A: {
            x_min: -3,
            x_max: 1369,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: -3,
            advanceWidth: 1366,
        },
        B: {
            x_min: 150,
            x_max: 1257,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 150,
            advanceWidth: 1366,
        },
        C: {
            x_min: 102,
            x_max: 1398,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 102,
            advanceWidth: 1479,
        },
        D: {
            x_min: 158,
            x_max: 1370,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 158,
            advanceWidth: 1479,
        },
        E: {
            x_min: 162,
            x_max: 1256,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 162,
            advanceWidth: 1366,
        },
        F: {
            x_min: 168,
            x_max: 1157,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 168,
            advanceWidth: 1251,
        },
        G: {
            x_min: 109,
            x_max: 1465,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 109,
            advanceWidth: 1593,
        },
        H: {
            x_min: 164,
            x_max: 1314,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 164,
            advanceWidth: 1479,
        },
        I: {
            x_min: 191,
            x_max: 385,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 191,
            advanceWidth: 569,
        },
        J: {
            x_min: 58.84976474807333,
            x_max: 865,
            y_min: -25,
            y_max: 1466,
            ha: 1491,
            leftSideBearing: 58,
            advanceWidth: 1024,
        },
        K: {
            x_min: 150,
            x_max: 1362,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 150,
            advanceWidth: 1366,
        },
        L: {
            x_min: 150,
            x_max: 1066,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 150,
            advanceWidth: 1139,
        },
        M: {
            x_min: 152,
            x_max: 1551,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 152,
            advanceWidth: 1706,
        },
        N: {
            x_min: 156,
            x_max: 1311,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 156,
            advanceWidth: 1479,
        },
        O: {
            x_min: 99,
            x_max: 1501,
            y_min: -25,
            y_max: 1492,
            ha: 1517,
            leftSideBearing: 99,
            advanceWidth: 1593,
        },
        P: {
            x_min: 158,
            x_max: 1277,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 158,
            advanceWidth: 1366,
        },
        Q: {
            x_min: 88,
            x_max: 1518,
            y_min: -114,
            y_max: 1492,
            ha: 1606,
            leftSideBearing: 88,
            advanceWidth: 1593,
        },
        R: {
            x_min: 161,
            x_max: 1453,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 161,
            advanceWidth: 1479,
        },
        S: {
            x_min: 92,
            x_max: 1259,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 92,
            advanceWidth: 1366,
        },
        T: {
            x_min: 48,
            x_max: 1210,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 48,
            advanceWidth: 1251,
        },
        U: {
            x_min: 161,
            x_max: 1314,
            y_min: -25,
            y_max: 1466,
            ha: 1491,
            leftSideBearing: 161,
            advanceWidth: 1479,
        },
        V: {
            x_min: 9,
            x_max: 1350,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 9,
            advanceWidth: 1366,
        },
        W: {
            x_min: 25,
            x_max: 1910,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 25,
            advanceWidth: 1933,
        },
        X: {
            x_min: 9,
            x_max: 1353,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 9,
            advanceWidth: 1366,
        },
        Y: {
            x_min: 6,
            x_max: 1350,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 6,
            advanceWidth: 1366,
        },
        Z: {
            x_min: 41,
            x_max: 1200,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 41,
            advanceWidth: 1251,
        },
        '[': {
            x_min: 139,
            x_max: 536,
            y_min: -407,
            y_max: 1466,
            ha: 1873,
            leftSideBearing: 139,
            advanceWidth: 569,
        },
        '\\': {
            x_min: 0,
            x_max: 569,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 0,
            advanceWidth: 569,
        },
        ']': {
            x_min: 39,
            x_max: 436,
            y_min: -407,
            y_max: 1466,
            ha: 1873,
            leftSideBearing: 39,
            advanceWidth: 569,
        },
        '^': {
            x_min: 54,
            x_max: 907,
            y_min: 690,
            y_max: 1491,
            ha: 801,
            leftSideBearing: 54,
            advanceWidth: 961,
        },
        _: {
            x_min: -31,
            x_max: 1162,
            y_min: -407,
            y_max: -277,
            ha: 130,
            leftSideBearing: -31,
            advanceWidth: 1139,
        },
        '`': {
            x_min: 89,
            x_max: 465,
            y_min: 1194,
            y_max: 1474,
            ha: 280,
            leftSideBearing: 89,
            advanceWidth: 682,
        },
        a: {
            x_min: 74,
            x_max: 1052,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 74,
            advanceWidth: 1139,
        },
        b: {
            x_min: 134,
            x_max: 1055,
            y_min: -24,
            y_max: 1466,
            ha: 1490,
            leftSideBearing: 134,
            advanceWidth: 1139,
        },
        c: {
            x_min: 80,
            x_max: 1005,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 80,
            advanceWidth: 1024,
        },
        d: {
            x_min: 70,
            x_max: 991,
            y_min: -24,
            y_max: 1466,
            ha: 1490,
            leftSideBearing: 70,
            advanceWidth: 1139,
        },
        e: {
            x_min: 75,
            x_max: 1054,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 75,
            advanceWidth: 1139,
        },
        f: {
            x_min: 19,
            x_max: 640,
            y_min: 0,
            y_max: 1491,
            ha: 1491,
            leftSideBearing: 19,
            advanceWidth: 569,
        },
        g: {
            x_min: 66,
            x_max: 1002,
            y_min: -431,
            y_max: 1086,
            ha: 1517,
            leftSideBearing: 66,
            advanceWidth: 1139,
        },
        h: {
            x_min: 135,
            x_max: 1000,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 135,
            advanceWidth: 1139,
        },
        i: {
            x_min: 136,
            x_max: 316,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 136,
            advanceWidth: 455,
        },
        j: {
            x_min: -94,
            x_max: 314,
            y_min: -431,
            y_max: 1466,
            ha: 1897,
            leftSideBearing: -94,
            advanceWidth: 455,
        },
        k: {
            x_min: 136,
            x_max: 1016,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 136,
            advanceWidth: 1024,
        },
        l: {
            x_min: 131,
            x_max: 311,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 131,
            advanceWidth: 455,
        },
        m: {
            x_min: 135,
            x_max: 1574,
            y_min: 0,
            y_max: 1086,
            ha: 1086,
            leftSideBearing: 135,
            advanceWidth: 1706,
        },
        n: {
            x_min: 135,
            x_max: 998,
            y_min: 0,
            y_max: 1086,
            ha: 1086,
            leftSideBearing: 135,
            advanceWidth: 1139,
        },
        o: {
            x_min: 68,
            x_max: 1063,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 68,
            advanceWidth: 1139,
        },
        p: {
            x_min: 135,
            x_max: 1057,
            y_min: -407,
            y_max: 1086,
            ha: 1493,
            leftSideBearing: 135,
            advanceWidth: 1139,
        },
        q: {
            x_min: 72,
            x_max: 992,
            y_min: -407,
            y_max: 1086,
            ha: 1493,
            leftSideBearing: 72,
            advanceWidth: 1139,
        },
        r: {
            x_min: 133,
            x_max: 710,
            y_min: 0,
            y_max: 1086,
            ha: 1086,
            leftSideBearing: 133,
            advanceWidth: 682,
        },
        s: {
            x_min: 63,
            x_max: 945,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 63,
            advanceWidth: 1024,
        },
        t: {
            x_min: 36,
            x_max: 554,
            y_min: -14,
            y_max: 1433,
            ha: 1447,
            leftSideBearing: 36,
            advanceWidth: 569,
        },
        u: {
            x_min: 131,
            x_max: 992,
            y_min: -24,
            y_max: 1062,
            ha: 1086,
            leftSideBearing: 131,
            advanceWidth: 1139,
        },
        v: {
            x_min: 26,
            x_max: 1000,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 26,
            advanceWidth: 1024,
        },
        w: {
            x_min: 6,
            x_max: 1463,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 6,
            advanceWidth: 1479,
        },
        x: {
            x_min: 15,
            x_max: 1009,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 15,
            advanceWidth: 1024,
        },
        y: {
            x_min: 33,
            x_max: 1006,
            y_min: -431,
            y_max: 1062,
            ha: 1493,
            leftSideBearing: 33,
            advanceWidth: 1024,
        },
        z: {
            x_min: 40,
            x_max: 980,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 40,
            advanceWidth: 1024,
        },
        '{': {
            x_min: 57,
            x_max: 636,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 57,
            advanceWidth: 684,
        },
        '|': {
            x_min: 188,
            x_max: 345,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 188,
            advanceWidth: 532,
        },
        '}': {
            x_min: 47,
            x_max: 626,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 47,
            advanceWidth: 684,
        },
        '~': {
            x_min: 87,
            x_max: 1110,
            y_min: 557,
            y_max: 885,
            ha: 328,
            leftSideBearing: 87,
            advanceWidth: 1196,
        },
    },
    fontFamily: 'sans-serif',
    resolution: 2048,
    generatedOn: '2020-10-18T18:48:11.823Z',
};
