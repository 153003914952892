export const SansFontBold = {
    glyphs: {
        '0': {
            x_min: 86,
            x_max: 1038,
            y_min: -25,
            y_max: 1472,
            ha: 1497,
            leftSideBearing: 86,
            advanceWidth: 1139,
        },
        '1': {
            x_min: 162,
            x_max: 806,
            y_min: 0,
            y_max: 1472,
            ha: 1472,
            leftSideBearing: 162,
            advanceWidth: 1139,
        },
        '2': {
            x_min: 51,
            x_max: 1036,
            y_min: 0,
            y_max: 1472,
            ha: 1472,
            leftSideBearing: 51,
            advanceWidth: 1139,
        },
        '3': {
            x_min: 77,
            x_max: 1051,
            y_min: -25,
            y_max: 1472,
            ha: 1497,
            leftSideBearing: 77,
            advanceWidth: 1139,
        },
        '4': {
            x_min: 38,
            x_max: 1092,
            y_min: 0,
            y_max: 1472,
            ha: 1472,
            leftSideBearing: 38,
            advanceWidth: 1139,
        },
        '5': {
            x_min: 91,
            x_max: 1077,
            y_min: -25,
            y_max: 1446,
            ha: 1471,
            leftSideBearing: 91,
            advanceWidth: 1139,
        },
        '6': {
            x_min: 87,
            x_max: 1066,
            y_min: -25,
            y_max: 1472,
            ha: 1497,
            leftSideBearing: 87,
            advanceWidth: 1139,
        },
        '7': {
            x_min: 87,
            x_max: 1048,
            y_min: 0,
            y_max: 1446,
            ha: 1446,
            leftSideBearing: 87,
            advanceWidth: 1139,
        },
        '8': {
            x_min: 83,
            x_max: 1047,
            y_min: -26,
            y_max: 1472,
            ha: 1498,
            leftSideBearing: 83,
            advanceWidth: 1139,
        },
        '9': {
            x_min: 65,
            x_max: 1044,
            y_min: -26,
            y_max: 1472,
            ha: 1498,
            leftSideBearing: 65,
            advanceWidth: 1139,
        },
        ' ': {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
            ha: 0,
            leftSideBearing: 0,
            advanceWidth: 569,
        },
        '!': {
            x_min: 184,
            x_max: 488,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 184,
            advanceWidth: 682,
        },
        '"': {
            x_min: 112,
            x_max: 869,
            y_min: 945,
            y_max: 1466,
            ha: 521,
            leftSideBearing: 112,
            advanceWidth: 971,
        },
        '#': {
            x_min: 18,
            x_max: 1115,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 18,
            advanceWidth: 1139,
        },
        $: {
            x_min: 70,
            x_max: 1048,
            y_min: -205,
            y_max: 1584,
            ha: 1789,
            leftSideBearing: 70,
            advanceWidth: 1139,
        },
        '%': {
            x_min: 89,
            x_max: 1725,
            y_min: -59,
            y_max: 1491,
            ha: 1550,
            leftSideBearing: 89,
            advanceWidth: 1821,
        },
        '&': {
            x_min: 90,
            x_max: 1446,
            y_min: -38,
            y_max: 1491,
            ha: 1529,
            leftSideBearing: 90,
            advanceWidth: 1479,
        },
        "'": {
            x_min: 92,
            x_max: 398,
            y_min: 945,
            y_max: 1466,
            ha: 521,
            leftSideBearing: 92,
            advanceWidth: 487,
        },
        '(': {
            x_min: 107,
            x_max: 616,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 107,
            advanceWidth: 682,
        },
        ')': {
            x_min: 67,
            x_max: 576,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 67,
            advanceWidth: 682,
        },
        '*': {
            x_min: 28,
            x_max: 753,
            y_min: 792,
            y_max: 1491,
            ha: 699,
            leftSideBearing: 28,
            advanceWidth: 797,
        },
        '+': {
            x_min: 85,
            x_max: 1110,
            y_min: 211,
            y_max: 1236,
            ha: 1025,
            leftSideBearing: 85,
            advanceWidth: 1196,
        },
        ',': {
            x_min: 117,
            x_max: 421,
            y_min: -327,
            y_max: 281,
            ha: 608,
            leftSideBearing: 117,
            advanceWidth: 569,
        },
        '-': {
            x_min: 115,
            x_max: 667,
            y_min: 391,
            y_max: 672,
            ha: 281,
            leftSideBearing: 115,
            advanceWidth: 682,
        },
        '.': {
            x_min: 147,
            x_max: 428,
            y_min: 0,
            y_max: 281,
            ha: 281,
            leftSideBearing: 147,
            advanceWidth: 569,
        },
        '/': {
            x_min: -3,
            x_max: 571,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: -3,
            advanceWidth: 569,
        },
        ':': {
            x_min: 201,
            x_max: 482,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 201,
            advanceWidth: 682,
        },
        ';': {
            x_min: 170,
            x_max: 474,
            y_min: -327,
            y_max: 1062,
            ha: 1389,
            leftSideBearing: 170,
            advanceWidth: 682,
        },
        '<': {
            x_min: 95,
            x_max: 1100,
            y_min: 167,
            y_max: 1281,
            ha: 1114,
            leftSideBearing: 95,
            advanceWidth: 1196,
        },
        '=': {
            x_min: 85,
            x_max: 1110,
            y_min: 372,
            y_max: 1074,
            ha: 702,
            leftSideBearing: 85,
            advanceWidth: 1196,
        },
        '>': {
            x_min: 95,
            x_max: 1101,
            y_min: 166,
            y_max: 1279,
            ha: 1113,
            leftSideBearing: 95,
            advanceWidth: 1196,
        },
        '?': {
            x_min: 106,
            x_max: 1158,
            y_min: 0,
            y_max: 1481,
            ha: 1481,
            leftSideBearing: 106,
            advanceWidth: 1251,
        },
        '@': {
            x_min: 61,
            x_max: 1990,
            y_min: -431,
            y_max: 1492,
            ha: 1923,
            leftSideBearing: 61,
            advanceWidth: 1997,
        },
        A: {
            x_min: 0,
            x_max: 1471,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 0,
            advanceWidth: 1479,
        },
        B: {
            x_min: 150,
            x_max: 1378,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 150,
            advanceWidth: 1479,
        },
        C: {
            x_min: 97,
            x_max: 1374,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 97,
            advanceWidth: 1479,
        },
        D: {
            x_min: 148,
            x_max: 1377,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 148,
            advanceWidth: 1479,
        },
        E: {
            x_min: 149,
            x_max: 1264,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 149,
            advanceWidth: 1366,
        },
        F: {
            x_min: 151,
            x_max: 1156,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 151,
            advanceWidth: 1251,
        },
        G: {
            x_min: 98,
            x_max: 1469,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 98,
            advanceWidth: 1593,
        },
        H: {
            x_min: 150,
            x_max: 1322,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 150,
            advanceWidth: 1479,
        },
        I: {
            x_min: 140,
            x_max: 436,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 140,
            advanceWidth: 569,
        },
        J: {
            x_min: 35,
            x_max: 973,
            y_min: -25,
            y_max: 1466,
            ha: 1491,
            leftSideBearing: 35,
            advanceWidth: 1139,
        },
        K: {
            x_min: 153,
            x_max: 1475,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 153,
            advanceWidth: 1479,
        },
        L: {
            x_min: 157,
            x_max: 1189,
            y_min: 0,
            y_max: 1454,
            ha: 1454,
            leftSideBearing: 157,
            advanceWidth: 1251,
        },
        M: {
            x_min: 145,
            x_max: 1561,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 145,
            advanceWidth: 1706,
        },
        N: {
            x_min: 152,
            x_max: 1315,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 152,
            advanceWidth: 1479,
        },
        O: {
            x_min: 89,
            x_max: 1511,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: 89,
            advanceWidth: 1593,
        },
        P: {
            x_min: 149,
            x_max: 1272,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 149,
            advanceWidth: 1366,
        },
        Q: {
            x_min: 89,
            x_max: 1566,
            y_min: -147,
            y_max: 1491,
            ha: 1638,
            leftSideBearing: 89,
            advanceWidth: 1593,
        },
        R: {
            x_min: 150,
            x_max: 1468,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 150,
            advanceWidth: 1479,
        },
        S: {
            x_min: 74,
            x_max: 1266,
            y_min: -26,
            y_max: 1491,
            ha: 1517,
            leftSideBearing: 74,
            advanceWidth: 1366,
        },
        T: {
            x_min: 44,
            x_max: 1209,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 44,
            advanceWidth: 1251,
        },
        U: {
            x_min: 147,
            x_max: 1316,
            y_min: -25,
            y_max: 1466,
            ha: 1491,
            leftSideBearing: 147,
            advanceWidth: 1479,
        },
        V: {
            x_min: -1,
            x_max: 1364,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: -1,
            advanceWidth: 1366,
        },
        W: {
            x_min: 7,
            x_max: 1931,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 7,
            advanceWidth: 1933,
        },
        X: {
            x_min: 0,
            x_max: 1363,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 0,
            advanceWidth: 1366,
        },
        Y: {
            x_min: -3,
            x_max: 1368,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: -3,
            advanceWidth: 1366,
        },
        Z: {
            x_min: 22,
            x_max: 1213,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 22,
            advanceWidth: 1251,
        },
        '[': {
            x_min: 146,
            x_max: 644,
            y_min: -413,
            y_max: 1466,
            ha: 1879,
            leftSideBearing: 146,
            advanceWidth: 682,
        },
        '\\': {
            x_min: -3,
            x_max: 571,
            y_min: -25,
            y_max: 1491,
            ha: 1516,
            leftSideBearing: -3,
            advanceWidth: 569,
        },
        ']': {
            x_min: 38,
            x_max: 536,
            y_min: -413,
            y_max: 1466,
            ha: 1879,
            leftSideBearing: 38,
            advanceWidth: 682,
        },
        '^': {
            x_min: 115,
            x_max: 1080,
            y_min: 692,
            y_max: 1491,
            ha: 799,
            leftSideBearing: 115,
            advanceWidth: 1196,
        },
        _: {
            x_min: -19,
            x_max: 1149,
            y_min: -405,
            y_max: -223,
            ha: 182,
            leftSideBearing: -19,
            advanceWidth: 1139,
        },
        '`': {
            x_min: 42,
            x_max: 495,
            y_min: 1192,
            y_max: 1491,
            ha: 299,
            leftSideBearing: 42,
            advanceWidth: 682,
        },
        a: {
            x_min: 73,
            x_max: 1070,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 73,
            advanceWidth: 1139,
        },
        b: {
            x_min: 135,
            x_max: 1172,
            y_min: -24,
            y_max: 1466,
            ha: 1490,
            leftSideBearing: 135,
            advanceWidth: 1251,
        },
        c: {
            x_min: 85,
            x_max: 1087,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 85,
            advanceWidth: 1139,
        },
        d: {
            x_min: 84,
            x_max: 1121,
            y_min: -24,
            y_max: 1466,
            ha: 1490,
            leftSideBearing: 84,
            advanceWidth: 1251,
        },
        e: {
            x_min: 65,
            x_max: 1057.2517482517483,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 65,
            advanceWidth: 1139,
        },
        f: {
            x_min: 24,
            x_max: 742,
            y_min: 0,
            y_max: 1491,
            ha: 1491,
            leftSideBearing: 24,
            advanceWidth: 682,
        },
        g: {
            x_min: 84,
            x_max: 1120,
            y_min: -431,
            y_max: 1086,
            ha: 1517,
            leftSideBearing: 84,
            advanceWidth: 1251,
        },
        h: {
            x_min: 146,
            x_max: 1113,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 146,
            advanceWidth: 1251,
        },
        i: {
            x_min: 147,
            x_max: 428,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 147,
            advanceWidth: 569,
        },
        j: {
            x_min: -94,
            x_max: 422,
            y_min: -431,
            y_max: 1466,
            ha: 1897,
            leftSideBearing: -94,
            advanceWidth: 569,
        },
        k: {
            x_min: 137,
            x_max: 1119,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 137,
            advanceWidth: 1139,
        },
        l: {
            x_min: 147,
            x_max: 428,
            y_min: 0,
            y_max: 1466,
            ha: 1466,
            leftSideBearing: 147,
            advanceWidth: 569,
        },
        m: {
            x_min: 126,
            x_max: 1688,
            y_min: 0,
            y_max: 1086,
            ha: 1086,
            leftSideBearing: 126,
            advanceWidth: 1821,
        },
        n: {
            x_min: 145,
            x_max: 1113,
            y_min: 0,
            y_max: 1086,
            ha: 1086,
            leftSideBearing: 145,
            advanceWidth: 1251,
        },
        o: {
            x_min: 82,
            x_max: 1178,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 82,
            advanceWidth: 1251,
        },
        p: {
            x_min: 139,
            x_max: 1175,
            y_min: -404,
            y_max: 1086,
            ha: 1490,
            leftSideBearing: 139,
            advanceWidth: 1251,
        },
        q: {
            x_min: 91,
            x_max: 1122,
            y_min: -404,
            y_max: 1086,
            ha: 1490,
            leftSideBearing: 91,
            advanceWidth: 1251,
        },
        r: {
            x_min: 135,
            x_max: 823,
            y_min: 0,
            y_max: 1086,
            ha: 1086,
            leftSideBearing: 135,
            advanceWidth: 797,
        },
        s: {
            x_min: 48,
            x_max: 1040,
            y_min: -24,
            y_max: 1086,
            ha: 1110,
            leftSideBearing: 48,
            advanceWidth: 1139,
        },
        t: {
            x_min: 31,
            x_max: 657,
            y_min: -24,
            y_max: 1437,
            ha: 1461,
            leftSideBearing: 31,
            advanceWidth: 682,
        },
        u: {
            x_min: 141,
            x_max: 1107,
            y_min: -24,
            y_max: 1062,
            ha: 1086,
            leftSideBearing: 141,
            advanceWidth: 1251,
        },
        v: {
            x_min: 11,
            x_max: 1114,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 11,
            advanceWidth: 1139,
        },
        w: {
            x_min: 9,
            x_max: 1592,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 9,
            advanceWidth: 1593,
        },
        x: {
            x_min: 12,
            x_max: 1120,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 12,
            advanceWidth: 1139,
        },
        y: {
            x_min: 14,
            x_max: 1106,
            y_min: -431,
            y_max: 1062,
            ha: 1493,
            leftSideBearing: 14,
            advanceWidth: 1139,
        },
        z: {
            x_min: 34,
            x_max: 982,
            y_min: 0,
            y_max: 1062,
            ha: 1062,
            leftSideBearing: 34,
            advanceWidth: 1024,
        },
        '{': {
            x_min: 60,
            x_max: 744,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 60,
            advanceWidth: 797,
        },
        '|': {
            x_min: 176,
            x_max: 399,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 176,
            advanceWidth: 573,
        },
        '}': {
            x_min: 45,
            x_max: 729,
            y_min: -431,
            y_max: 1491,
            ha: 1922,
            leftSideBearing: 45,
            advanceWidth: 797,
        },
        '~': {
            x_min: 67,
            x_max: 1129,
            y_min: 519,
            y_max: 924,
            ha: 405,
            leftSideBearing: 67,
            advanceWidth: 1196,
        },
    },
    fontFamily: 'Arial',
    resolution: 2048,
    generatedOn: '2022-11-04T20:05:21.000Z',
};
