export const SerifFont = {
    smufl: false,
    name: 'SERIF',
    spacing: 50,
    Description: 'Built-in serif font',
    bold: true,
    italic: true,
    monospaced: false,
    serifs: true,
    glyphs: {
        '0': {
            x_min: 49,
            x_max: 975,
            y_min: -27,
            y_max: 1383,
            ha: 1410,
            leftSideBearing: 49,
            advanceWidth: 1024,
        },
        '1': {
            x_min: 227,
            x_max: 811,
            y_min: 0,
            y_max: 1383,
            ha: 1383,
            leftSideBearing: 227,
            advanceWidth: 1024,
        },
        '2': {
            x_min: 61,
            x_max: 965,
            y_min: 0,
            y_max: 1383,
            ha: 1383,
            leftSideBearing: 61,
            advanceWidth: 1024,
        },
        '3': {
            x_min: 88,
            x_max: 883,
            y_min: -27,
            y_max: 1383,
            ha: 1410,
            leftSideBearing: 88,
            advanceWidth: 1024,
        },
        '4': {
            x_min: 25,
            x_max: 967,
            y_min: 0,
            y_max: 1384,
            ha: 1384,
            leftSideBearing: 25,
            advanceWidth: 1024,
        },
        '5': {
            x_min: 66,
            x_max: 898,
            y_min: -27,
            y_max: 1409,
            ha: 1436,
            leftSideBearing: 66,
            advanceWidth: 1024,
        },
        '6': {
            x_min: 70,
            x_max: 958,
            y_min: -27,
            y_max: 1401,
            ha: 1428,
            leftSideBearing: 70,
            advanceWidth: 1024,
        },
        '7': {
            x_min: 41,
            x_max: 920,
            y_min: -16,
            y_max: 1356,
            ha: 1372,
            leftSideBearing: 41,
            advanceWidth: 1024,
        },
        '8': {
            x_min: 115,
            x_max: 911,
            y_min: -27,
            y_max: 1383,
            ha: 1410,
            leftSideBearing: 115,
            advanceWidth: 1024,
        },
        '9': {
            x_min: 61,
            x_max: 940,
            y_min: -41,
            y_max: 1383,
            ha: 1424,
            leftSideBearing: 61,
            advanceWidth: 1024,
        },
        ' ': {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
            ha: 0,
            leftSideBearing: 0,
            advanceWidth: 512,
        },
        '!': {
            x_min: 266,
            x_max: 488,
            y_min: -22,
            y_max: 1383,
            ha: 1405,
            leftSideBearing: 266,
            advanceWidth: 682,
        },
        '"': {
            x_min: 157.2000021972655,
            x_max: 678.2500021457677,
            y_min: 881,
            y_max: 1385,
            ha: 504,
            leftSideBearing: 157,
            advanceWidth: 836,
        },
        '#': {
            x_min: 10,
            x_max: 1016,
            y_min: -1,
            y_max: 1356,
            ha: 1357,
            leftSideBearing: 10,
            advanceWidth: 1024,
        },
        $: {
            x_min: 90,
            x_max: 936,
            y_min: -180,
            y_max: 1492,
            ha: 1672,
            leftSideBearing: 90,
            advanceWidth: 1024,
        },
        '%': {
            x_min: 125,
            x_max: 1581,
            y_min: -30,
            y_max: 1388,
            ha: 1418,
            leftSideBearing: 125,
            advanceWidth: 1706,
        },
        '&': {
            x_min: 86,
            x_max: 1536,
            y_min: -28,
            y_max: 1383,
            ha: 1411,
            leftSideBearing: 86,
            advanceWidth: 1593,
        },
        "'": {
            x_min: 97.20000219726548,
            x_max: 273.2500021457677,
            y_min: 881,
            y_max: 1385,
            ha: 504,
            leftSideBearing: 97,
            advanceWidth: 369,
        },
        '(': {
            x_min: 98,
            x_max: 623,
            y_min: -363,
            y_max: 1383,
            ha: 1746,
            leftSideBearing: 98,
            advanceWidth: 682,
        },
        ')': {
            x_min: 59,
            x_max: 584,
            y_min: -363,
            y_max: 1383,
            ha: 1746,
            leftSideBearing: 59,
            advanceWidth: 682,
        },
        '*': {
            x_min: 137.96078522291893,
            x_max: 886.039214777081,
            y_min: 543,
            y_max: 1383,
            ha: 840,
            leftSideBearing: 137,
            advanceWidth: 1024,
        },
        '+': {
            x_min: 61,
            x_max: 1093,
            y_min: 2,
            y_max: 1034,
            ha: 1032,
            leftSideBearing: 61,
            advanceWidth: 1155,
        },
        ',': {
            x_min: 115,
            x_max: 399,
            y_min: -291,
            y_max: 208,
            ha: 499,
            leftSideBearing: 115,
            advanceWidth: 512,
        },
        '-': {
            x_min: 80,
            x_max: 584,
            y_min: 396,
            y_max: 525,
            ha: 129,
            leftSideBearing: 80,
            advanceWidth: 682,
        },
        '.': {
            x_min: 143,
            x_max: 371,
            y_min: -22,
            y_max: 205,
            ha: 227,
            leftSideBearing: 143,
            advanceWidth: 512,
        },
        '/': {
            x_min: -17,
            x_max: 586,
            y_min: -27,
            y_max: 1383,
            ha: 1410,
            leftSideBearing: -17,
            advanceWidth: 569,
        },
        ':': {
            x_min: 166,
            x_max: 394,
            y_min: -22,
            y_max: 943,
            ha: 965,
            leftSideBearing: 166,
            advanceWidth: 569,
        },
        ';': {
            x_min: 164,
            x_max: 448,
            y_min: -290,
            y_max: 943,
            ha: 1233,
            leftSideBearing: 164,
            advanceWidth: 569,
        },
        '<': {
            x_min: 57,
            x_max: 1098,
            y_min: -15,
            y_max: 1051,
            ha: 1066,
            leftSideBearing: 57,
            advanceWidth: 1155,
        },
        '=': {
            x_min: 61,
            x_max: 1093,
            y_min: 246,
            y_max: 791,
            ha: 545,
            leftSideBearing: 61,
            advanceWidth: 1155,
        },
        '>': {
            x_min: 57,
            x_max: 1098,
            y_min: -15,
            y_max: 1051,
            ha: 1066,
            leftSideBearing: 57,
            advanceWidth: 1155,
        },
        '?': {
            x_min: 139,
            x_max: 848,
            y_min: -15,
            y_max: 1383,
            ha: 1398,
            leftSideBearing: 139,
            advanceWidth: 909,
        },
        '@': {
            x_min: 238,
            x_max: 1657,
            y_min: -29,
            y_max: 1386,
            ha: 1415,
            leftSideBearing: 238,
            advanceWidth: 1886,
        },
        A: {
            x_min: 31,
            x_max: 1445,
            y_min: 0,
            y_max: 1380,
            ha: 1380,
            leftSideBearing: 31,
            advanceWidth: 1479,
        },
        B: {
            x_min: 35,
            x_max: 1214,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 35,
            advanceWidth: 1366,
        },
        C: {
            x_min: 57,
            x_max: 1296,
            y_min: -28,
            y_max: 1383,
            ha: 1411,
            leftSideBearing: 57,
            advanceWidth: 1366,
        },
        D: {
            x_min: 33,
            x_max: 1403,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 33,
            advanceWidth: 1479,
        },
        E: {
            x_min: 25,
            x_max: 1222,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 25,
            advanceWidth: 1251,
        },
        F: {
            x_min: 25,
            x_max: 1119,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 25,
            advanceWidth: 1139,
        },
        G: {
            x_min: 66,
            x_max: 1452,
            y_min: -28,
            y_max: 1383,
            ha: 1411,
            leftSideBearing: 66,
            advanceWidth: 1479,
        },
        H: {
            x_min: 39,
            x_max: 1438,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 39,
            advanceWidth: 1479,
        },
        I: {
            x_min: 37,
            x_max: 642,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 37,
            advanceWidth: 682,
        },
        J: {
            x_min: 20,
            x_max: 758,
            y_min: -28,
            y_max: 1356,
            ha: 1384,
            leftSideBearing: 20,
            advanceWidth: 797,
        },
        K: {
            x_min: 70,
            x_max: 1479,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 70,
            advanceWidth: 1479,
        },
        L: {
            x_min: 25,
            x_max: 1224,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 25,
            advanceWidth: 1251,
        },
        M: {
            x_min: 25,
            x_max: 1768,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 25,
            advanceWidth: 1821,
        },
        N: {
            x_min: 25,
            x_max: 1450,
            y_min: -22,
            y_max: 1356,
            ha: 1378,
            leftSideBearing: 25,
            advanceWidth: 1479,
        },
        O: {
            x_min: 70,
            x_max: 1409,
            y_min: -28,
            y_max: 1383,
            ha: 1411,
            leftSideBearing: 70,
            advanceWidth: 1479,
        },
        P: {
            x_min: 33,
            x_max: 1110,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 33,
            advanceWidth: 1139,
        },
        Q: {
            x_min: 70,
            x_max: 1435,
            y_min: -364.28571588721996,
            y_max: 1383,
            ha: 1747.28571588722,
            leftSideBearing: 70,
            advanceWidth: 1479,
        },
        R: {
            x_min: 35,
            x_max: 1347,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 35,
            advanceWidth: 1366,
        },
        S: {
            x_min: 86,
            x_max: 1006,
            y_min: -28,
            y_max: 1383,
            ha: 1411,
            leftSideBearing: 86,
            advanceWidth: 1139,
        },
        T: {
            x_min: 35,
            x_max: 1214,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 35,
            advanceWidth: 1251,
        },
        U: {
            x_min: 29,
            x_max: 1444,
            y_min: -28,
            y_max: 1356,
            ha: 1384,
            leftSideBearing: 29,
            advanceWidth: 1479,
        },
        V: {
            x_min: 33,
            x_max: 1428,
            y_min: -22,
            y_max: 1356,
            ha: 1378,
            leftSideBearing: 33,
            advanceWidth: 1479,
        },
        W: {
            x_min: 10,
            x_max: 1906,
            y_min: -22,
            y_max: 1356,
            ha: 1378,
            leftSideBearing: 10,
            advanceWidth: 1933,
        },
        X: {
            x_min: 20,
            x_max: 1449,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 20,
            advanceWidth: 1479,
        },
        Y: {
            x_min: 45,
            x_max: 1441,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 45,
            advanceWidth: 1479,
        },
        Z: {
            x_min: 18,
            x_max: 1222,
            y_min: 0,
            y_max: 1356,
            ha: 1356,
            leftSideBearing: 18,
            advanceWidth: 1251,
        },
        '[': {
            x_min: 180,
            x_max: 612,
            y_min: -319,
            y_max: 1355,
            ha: 1674,
            leftSideBearing: 180,
            advanceWidth: 682,
        },
        '\\': {
            x_min: -17,
            x_max: 586,
            y_min: -27,
            y_max: 1383,
            ha: 1410,
            leftSideBearing: -17,
            advanceWidth: 569,
        },
        ']': {
            x_min: 70,
            x_max: 502,
            y_min: -319,
            y_max: 1355,
            ha: 1674,
            leftSideBearing: 70,
            advanceWidth: 682,
        },
        '^': {
            x_min: 49,
            x_max: 914,
            y_min: 608,
            y_max: 1355,
            ha: 747,
            leftSideBearing: 49,
            advanceWidth: 961,
        },
        _: {
            x_min: 0,
            x_max: 1024,
            y_min: -255,
            y_max: -153,
            ha: 102,
            leftSideBearing: 0,
            advanceWidth: 1024,
        },
        '`': {
            x_min: 39,
            x_max: 499,
            y_min: 1037,
            y_max: 1392.509803639748,
            ha: 355.50980363974804,
            leftSideBearing: 39,
            advanceWidth: 682,
        },
        a: {
            x_min: 76,
            x_max: 905,
            y_min: -20,
            y_max: 942,
            ha: 962,
            leftSideBearing: 76,
            advanceWidth: 909,
        },
        b: {
            x_min: 6,
            x_max: 958,
            y_min: -20,
            y_max: 1399,
            ha: 1419,
            leftSideBearing: 6,
            advanceWidth: 1024,
        },
        c: {
            x_min: 51,
            x_max: 843,
            y_min: -20,
            y_max: 942,
            ha: 962,
            leftSideBearing: 51,
            advanceWidth: 909,
        },
        d: {
            x_min: 55,
            x_max: 1006,
            y_min: -20,
            y_max: 1399,
            ha: 1419,
            leftSideBearing: 55,
            advanceWidth: 1024,
        },
        e: {
            x_min: 51,
            x_max: 868,
            y_min: -20,
            y_max: 942,
            ha: 962,
            leftSideBearing: 51,
            advanceWidth: 909,
        },
        f: {
            x_min: 41,
            x_max: 783,
            y_min: 0,
            y_max: 1399,
            ha: 1399,
            leftSideBearing: 41,
            advanceWidth: 682,
        },
        g: {
            x_min: 57,
            x_max: 963,
            y_min: -446,
            y_max: 942,
            ha: 1388,
            leftSideBearing: 57,
            advanceWidth: 1024,
        },
        h: {
            x_min: 18,
            x_max: 997,
            y_min: 0,
            y_max: 1399,
            ha: 1399,
            leftSideBearing: 18,
            advanceWidth: 1024,
        },
        i: {
            x_min: 33,
            x_max: 516,
            y_min: 0,
            y_max: 1399,
            ha: 1399,
            leftSideBearing: 33,
            advanceWidth: 569,
        },
        j: {
            x_min: -142,
            x_max: 397,
            y_min: -446,
            y_max: 1399,
            ha: 1845,
            leftSideBearing: -142,
            advanceWidth: 569,
        },
        k: {
            x_min: 14,
            x_max: 1029,
            y_min: 0,
            y_max: 1399,
            ha: 1399,
            leftSideBearing: 14,
            advanceWidth: 1024,
        },
        l: {
            x_min: 39,
            x_max: 523,
            y_min: 0,
            y_max: 1399,
            ha: 1399,
            leftSideBearing: 39,
            advanceWidth: 569,
        },
        m: {
            x_min: 33,
            x_max: 1587,
            y_min: 0,
            y_max: 944,
            ha: 944,
            leftSideBearing: 33,
            advanceWidth: 1593,
        },
        n: {
            x_min: 33,
            x_max: 993,
            y_min: 0,
            y_max: 944,
            ha: 944,
            leftSideBearing: 33,
            advanceWidth: 1024,
        },
        o: {
            x_min: 59,
            x_max: 963,
            y_min: -20,
            y_max: 942,
            ha: 962,
            leftSideBearing: 59,
            advanceWidth: 1024,
        },
        p: {
            x_min: 10,
            x_max: 964,
            y_min: -443,
            y_max: 944,
            ha: 1387,
            leftSideBearing: 10,
            advanceWidth: 1024,
        },
        q: {
            x_min: 49,
            x_max: 999,
            y_min: -443,
            y_max: 942.0135137169275,
            ha: 1385.0135137169275,
            leftSideBearing: 49,
            advanceWidth: 1024,
        },
        r: {
            x_min: 10,
            x_max: 685,
            y_min: 0,
            y_max: 944,
            ha: 944,
            leftSideBearing: 10,
            advanceWidth: 682,
        },
        s: {
            x_min: 104,
            x_max: 713,
            y_min: -20,
            y_max: 942.0263161804552,
            ha: 962.0263161804552,
            leftSideBearing: 104,
            advanceWidth: 797,
        },
        t: {
            x_min: 27,
            x_max: 572,
            y_min: -18,
            y_max: 1186,
            ha: 1204,
            leftSideBearing: 27,
            advanceWidth: 569,
        },
        u: {
            x_min: 18,
            x_max: 981,
            y_min: -21,
            y_max: 921,
            ha: 942,
            leftSideBearing: 18,
            advanceWidth: 1024,
        },
        v: {
            x_min: 39,
            x_max: 976,
            y_min: -28,
            y_max: 921,
            ha: 949,
            leftSideBearing: 39,
            advanceWidth: 1024,
        },
        w: {
            x_min: 43,
            x_max: 1423,
            y_min: -28,
            y_max: 921,
            ha: 949,
            leftSideBearing: 43,
            advanceWidth: 1479,
        },
        x: {
            x_min: 35,
            x_max: 989,
            y_min: 0,
            y_max: 921,
            ha: 921,
            leftSideBearing: 35,
            advanceWidth: 1024,
        },
        y: {
            x_min: 29,
            x_max: 976,
            y_min: -445,
            y_max: 921,
            ha: 1366,
            leftSideBearing: 29,
            advanceWidth: 1024,
        },
        z: {
            x_min: 55,
            x_max: 855,
            y_min: 0,
            y_max: 921,
            ha: 921,
            leftSideBearing: 55,
            advanceWidth: 909,
        },
        '{': {
            x_min: 205,
            x_max: 717,
            y_min: -377,
            y_max: 1397,
            ha: 1774,
            leftSideBearing: 205,
            advanceWidth: 983,
        },
        '|': {
            x_min: 137,
            x_max: 273,
            y_min: -512,
            y_max: 1535,
            ha: 2047,
            leftSideBearing: 137,
            advanceWidth: 410,
        },
        '}': {
            x_min: 266,
            x_max: 778,
            y_min: -377,
            y_max: 1397,
            ha: 1774,
            leftSideBearing: 266,
            advanceWidth: 983,
        },
        '~': {
            x_min: 82,
            x_max: 1028,
            y_min: 380,
            y_max: 666,
            ha: 286,
            leftSideBearing: 82,
            advanceWidth: 1108,
        },
    },
    fontFamily: 'serif',
    resolution: 2048,
    generatedOn: '2020-10-18T19:03:12.514Z',
};
